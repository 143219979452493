// Generated by Framer (722666c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["zWudCSluT", "YgAxc4Dlq", "lVMByKngm"];

const serializationHash = "framer-aRwD0"

const variantClassNames = {lVMByKngm: "framer-v-1x7vpou", YgAxc4Dlq: "framer-v-qsm5ea", zWudCSluT: "framer-v-p1c5w8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const transition2 = {delay: 0, duration: 11.8, ease: [0, 0, 1, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "zWudCSluT", "Variant 3": "lVMByKngm", "Variant 4": "YgAxc4Dlq"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "zWudCSluT"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "zWudCSluT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearen57qo = activeVariantCallback(async (...args) => {
await delay(() => setVariant("lVMByKngm"), 100)
})

const onAppear1fdol1a = activeVariantCallback(async (...args) => {
await delay(() => setVariant("YgAxc4Dlq"), 100)
})

useOnVariantChange(baseVariant, {default: onAppearen57qo, lVMByKngm: onAppear1fdol1a, YgAxc4Dlq: undefined})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({lVMByKngm: {value: transition2}, YgAxc4Dlq: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-p1c5w8", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"zWudCSluT"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({lVMByKngm: {"data-framer-name": "Variant 3"}, YgAxc4Dlq: {"data-framer-name": "Variant 4", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-waat6i"} layoutDependency={layoutDependency} layoutId={"nolFQiwAN"} style={{backgroundColor: "var(--token-f291c9e3-d009-4a64-9a24-ec7b7592bf11, rgb(235, 247, 255))"}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-aRwD0.framer-1kxfbwd, .framer-aRwD0 .framer-1kxfbwd { display: block; }", ".framer-aRwD0.framer-p1c5w8 { height: 71px; overflow: hidden; position: relative; width: 159px; }", ".framer-aRwD0 .framer-waat6i { bottom: 0px; flex: none; left: -1px; overflow: hidden; position: absolute; top: 0px; width: 1px; }", ".framer-aRwD0.framer-v-qsm5ea .framer-waat6i { left: 0px; right: 0px; width: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 71
 * @framerIntrinsicWidth 159
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YgAxc4Dlq":{"layout":["fixed","fixed"]},"lVMByKngm":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOfvndAZ9r: React.ComponentType<Props> = withCSS(Component, css, "framer-aRwD0") as typeof Component;
export default FramerOfvndAZ9r;

FramerOfvndAZ9r.displayName = "Progress";

FramerOfvndAZ9r.defaultProps = {height: 71, width: 159};

addPropertyControls(FramerOfvndAZ9r, {variant: {options: ["zWudCSluT", "YgAxc4Dlq", "lVMByKngm"], optionTitles: ["Variant 1", "Variant 4", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerOfvndAZ9r, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})